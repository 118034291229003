<template>
  <div class="bodycontain">
    <template v-if="infodata">
      <div class="outcontain">
        <div class="titletext">{{ iteminfo.title }}</div>
        <div class="itemcontain">
          <div class="itemleft">
            <div class="text">姓名:</div>
            <div class="text text2">
              {{ iteminfo.user_name }} / {{ iteminfo.student_sn }}
            </div>
            <!-- <div class="text"> </div> -->
          </div>
          <div class="itemright">
            <div class="text">实习形式:</div>
            <div class="text text2">{{ iteminfo.type | typeformat }}</div>
          </div>
        </div>
        <div class="itemcontain">
          <div class="itemleft">
            <div class="text">所在院系:</div>
            <div class="text text2">{{ iteminfo.college_name }}/</div>
            <div class="text">{{ iteminfo.major_name }}/</div>
            <div class="text">{{ iteminfo.class_name }}</div>
          </div>
          <div class="itemright">
            <div class="text">实习时间:</div>
            <div class="text text2">
              {{ iteminfo.start_date | dataformat }} ~
              {{ iteminfo.end_date | dataformat }}
            </div>
          </div>
        </div>
        <div class="itemcontain">
          <div class="itemleft">
            <div class="text">实习计划:</div>
            <div class="text text2">{{ iteminfo.plan_name }}</div>
          </div>
          <div class="itemright">
            <div class="text">总结时间:</div>
            <div class="text text2">{{ infodata.created_at | dataformat }}</div>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="title">实习标题</div>
        <div class="contenttext">{{ infodata.title }}</div>
        <div class="title">实习内容</div>
        <div class="contenttext">
          <div class="mine_cont" v-html="infodata.content"></div>
        </div>
        <!-- 目前后端储存数据地址在调整,设计附件上传以及下载的部分都进行隐藏 v-if="false" -->
        <template v-if="Array.isArray(infodata.file)">
          <div class="downbt bt">附件：</div>
          <div
            class="downbt"
            v-for="(item, index) in infodata.file"
            :key="index"
          >
            <div class="downbt_name">{{ item.name }}</div>
            <!-- <el-image class="elimage" :src="icon1" /> -->
            <el-image class="elimage" :src="icon2" @click="download(item)" />
          </div>
        </template>
        <template v-else>
          <div class="downbt bt">附件：</div>
          <div class="downbt_name">{{ infodata.file }}</div>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import dayjs from "dayjs";
import Mainpage from "@/views/news/components/mainpage.vue";

import {
  get_report_deatil,
  getinfopracticestudentreport,
} from "@/api/practicestudentreport";

const typemap = ["自主报名", "集中安排"];

export default {
  name: "News",
  components: {
    Mainpage,
  },
  data() {
    return {
      icon1: require("@/assets/查看.png"),
      icon2: require("@/assets/下载.png"),
      infodata: {},
      iteminfo: {},
      id: "",
      practice_plan_id: "",
      student_id: "",
    };
  },
  filters: {
    typeformat(val) {
      return typemap[val];
    },
    dataformat(value) {
      if (!value) return "";
      return dayjs.unix(value).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  created() {},
  methods: {
    initdata(item) {
      this.id = item.id;
      this.practice_plan_id = item.practice_plan_id;
      this.student_id = item.student_id ? item.student_id : "";
      this.initLogInfo();
      this.init_repoet_info();
    },
    initLogInfo() {
      const params = {
        id: this.id,
        practice_plan_id: this.practice_plan_id,
        student_id: this.student_id,
      };
      getinfopracticestudentreport(params).then((res) => {
        if (res.code === 0) {
          let obj = res.data;
          obj.content = this.AutoCorrection(obj.content);
          this.infodata = obj;
        }
      });
    },
    // 循环修正数据与数据格式
    AutoCorrection(str) {
      const that = this;
      let newStr = str.replace(/[\r\n]/g, "").trim(); // 去与换行与前后空格
      newStr = newStr.replace(/\\/g, ""); // 去除反斜杠
      newStr = newStr.replace(/\s+/g, " "); // 去除多余空格
      // newStr = newStr.replace(/<p>(\s|\&nbsp\;| |　|\xc2\xa0)*<\/p>/ig, ""); // 去掉多余 P 标签
      // 判断是否有图片，如果有图片
      let isHavePic = newStr.includes("<img");
      if (isHavePic) {
        // 正则过滤出图片
        newStr.replace(/<img.+?>/ig, function (matchImg, captureImg) {
          // console.log("正则匹配所有图片并获取相应下标==>",matchImg, captureImg);
          // newStr = newStr.replace(match,""); // 清除图片
          let resImgSrc = '' // 自定义变量接收图片
          matchImg.replace(/<img[^>]*src=['"]([^'"]+)[^>]*>/gi, function (matchSrc, captureSrc) {
            // console.log("正则匹配该图片并获取相应路径==>",matchSrc, captureSrc);
            resImgSrc = captureSrc.replace("../uploads", that.$api.baseUrl + "/uploads")
          })
          // 在图片中插入点击事件并默认不展示
          let newImgStr = matchImg.replace(
            /<s?img[^>]*src=['"]([^'"]+)/gi,
            `<img src="${resImgSrc}" style="max-width:80%; margin: 4px;"`
          );
          newStr = newStr.replace(matchImg, newImgStr);
        });
      }
      // 判断是否有视频，如果有视频
      let isHaveMove = newStr.includes("<video");
      if (isHaveMove) {
        // 正则过滤出视频
        newStr.replace(/(<video[\s\S]*?>)[\s\S]*?(<\/video>)/ig, function (matchVideo, captureVideo) {
          // console.log("正则匹配所有视频并获取相应下标==>",matchVideo, captureVideo);
          // newStr = newStr.replace(matchVideo,""); // 清空视频
          let resMoveurl = '' // 自定义变量接收视频
          matchVideo.replace(/<source [^>]*src=['"]([^'"]+)[^>]*>/gi, function (matchUrl, captureUrl) {
            // console.log("正则匹配该视频并获取相应路径==>",matchUrl, captureUrl);
            resMoveurl = captureUrl.replace("../uploads", that.$api.baseUrl + "/uploads")
          })
          let newMoveStr = matchVideo.replace(/<s?video[\s\S]*?>[\s\S]*?<\/video>/gi,
            `<video controls="controls" style="max-width:80%; margin: 4px;"><source src="${resMoveurl}" type="video/mp4"/></video>`)
          newStr = newStr.replace(matchVideo, newMoveStr)
        })
      }
      return newStr;
    },
    init_repoet_info() {
      const params = {
        practice_plan_id: this.practice_plan_id,
        student_id: this.student_id,
      };
      get_report_deatil(params).then((res) => {
        if (res.code === 0) {
          this.iteminfo = res.data;
        }
      });
    },
    download(item) {
      var ele = document.createElement("a"); // 创建下载链接
      ele.download = item.name; //设置下载的名称
      ele.style.display = "none"; // 隐藏的可下载链接
      ele.href = item.url;
      // 绑定点击时间
      document.body.appendChild(ele);
      ele.click();
      // 然后移除
      document.body.removeChild(ele);
    },
  },
};
</script>
<style scoped lang="scss">
@media screen and (max-width: 640px) {
  .bodycontain {
    .outcontain {
      // padding: 30px;
      // padding-top: 20px;
      padding-bottom: 10px;
      border-bottom: 1px solid #dedede;

      .titletext {
        margin-bottom: 16px;
        font-size: 16px;
        font-weight: 700;
        color: #3a3a3a;
      }

      .itemcontain {
        margin-bottom: 10px;
        // padding-left: 20px;
        // display: flex;
        // justify-content: flex-start;
        // align-items: center;

        .itemleft {
          width: 100%;
          display: flex;
          flex-basis: 400px;
          justify-content: flex-start;
          align-items: center;
          line-height: 30px;
        }

        .itemright {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
           line-height: 30px;
          .text {
            min-width: 80px;
          }
        }

        .text {
          font-size: 16px;
          font-weight: 400;
          color: #3a3a3a;
        }

        .text2 {
          margin-left: 10px;
        }
      }
    }

    .content {
      min-height: 300px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-direction: column;
      margin-top: 20px;
      .title {
        width: 100%;
        line-height: 30px;
        font-size: 16px;
        text-indent: 2em;
        padding-bottom: 10px;
      }

      .contenttext {
        // padding: 20px;
        width: 100%;
        box-sizing: border-box;
        padding-left: 30px;
        padding-bottom: 20px;
        ::v-deep .mine_cont {
          width: 100%;

          img {
            max-width: 100% !important;
            max-height: 800px;
          }
        }
      }

      .downbt {
        margin-top: 15px;
        margin-left: 20px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .elimage {
          margin-left: 10px;
          cursor: pointer;
        }

        .downbt_name {
          color: #3d84ff;
        }
      }

      .bt {
        font-size: 16px;
        font-weight: bold;
        color: #000;
      }
    }
  }
}
@media screen and (min-width: 1200px) {
  .bodycontain {
    .outcontain {
      padding: 30px;
      padding-top: 20px;
      padding-bottom: 10px;
      border-bottom: 1px solid #dedede;

      .titletext {
        margin-bottom: 16px;
        font-size: 16px;
        font-weight: 700;
        color: #3a3a3a;
      }

      .itemcontain {
        margin-bottom: 20px;
        padding-left: 20px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .itemleft {
          width: 50%;
          display: flex;
          flex-basis: 400px;
          justify-content: flex-start;
          align-items: center;
        }

        .itemright {
          width: 50%;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .text {
            min-width: 80px;
          }
        }

        .text {
          font-size: 16px;
          font-weight: 400;
          color: #3a3a3a;
        }

        .text2 {
          margin-left: 10px;
        }
      }
    }

    .content {
      min-height: 300px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-direction: column;
      margin-top: 20px;
      .title {
        width: 100%;
        line-height: 30px;
        font-size: 16px;
        text-indent: 2em;
        padding-bottom: 10px;
      }

      .contenttext {
        // padding: 20px;
        width: 100%;
        box-sizing: border-box;
        padding-left: 30px;
        padding-bottom: 20px;
        ::v-deep .mine_cont {
          width: 100%;

          img {
            max-width: 100% !important;
            max-height: 800px;
          }
        }
      }

      .downbt {
        margin-top: 15px;
        margin-left: 20px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .elimage {
          margin-left: 10px;
          cursor: pointer;
        }

        .downbt_name {
          color: #3d84ff;
        }
      }

      .bt {
        font-size: 16px;
        font-weight: bold;
        color: #000;
      }
    }
  }
}
</style>
